import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { WebapiService } from '../webapi.service';

@Component({
  selector: 'app-ociean-prediction',
  templateUrl: './ociean-prediction.component.html',
  styleUrls: ['./ociean-prediction.component.scss'],
})
export class OcieanPredictionComponent implements OnInit {

  month=6;
  searchFactore={
    lane:"HKG-GDL",
    origin:"HKG",
    destination:"JFK",
    type:"Market Rate Prediction 40ft Dry"
  }
  tflag=2;
  llist=[
    {
      lane:"",
      origin:"HKG",
      destination:"AMS"
    },
    {
      lane:"",
      origin:"HKG",
      destination:"JFK"
    },
    {
      lane:"",
      origin:"HKG",
      destination:"LAX"
    },
    {
      lane:"",
      origin:"PVG",
      destination:"AMS"
    },
    {
      lane:"",
      origin:"PVG",
      destination:"JFK"
    },
    {
      lane:"",
      origin:"PVG",
      destination:"LAX"
    },
    {
      lane:"",
      origin:"TPE",
      destination:"AMS"
    },
    {
      lane:"",
      origin:"TPE",
      destination:"JFK"
    },
    {
      lane:"",
      origin:"TPE",
      destination:"LAX"
    },
    {
      lane:"",
      origin:"TPE",
      destination:"ORD"
    },
    {
      lane:"",
      origin:"TPE",
      destination:"HKG"
    }
  ]
  selectedindex=0
  alist=[
    {
 lane:"TPE-JFK",  
 listk:[]  
 },
 {
  lane:";Network",
  listk:[]
 }
]
  page=0;
  LineChartdata:any; 
  list=[];
sublist=[];
key=[];
gflag=false;
networklist=[];
keynetwork=[];
lanelist=[];
opt={minimumFractionDigits:0, maximumFractionDigits: 0}
lc:any;

  constructor(public modalController: ModalController,private activatedRoute: ActivatedRoute,public router: Router,public service: WebapiService) {

    this.service.postData(this.searchFactore, 'get_ociean_prediction_lane').then((result) => {

      var temp2: any = result;
      this.lanelist=temp2.lane;
      console.log(this.lanelist);
      this.searchFactore.lane=this.lanelist[0];
      this.onChangeFunction();
    });


    
    // this.service.postData(this.searchFactore, 'get_ociean_prediction_by_lane').then((result) => {

    //   var temp2: any = result;
    //   // this.lanelist=JSON.parse(temp2.lane);
    //   this.list=JSON.parse(temp2.data);
    //   console.log(this.list);
    //   if(this.list.length>0)
    //   {
    //     // this.list.reverse();
    //     this.sublist= this.list.slice(13, 24);   


    //     var dataTable=[];
      
    //     this.LineChartdata.dataTable.push(['Month','40ft Dry Actual rate','Bunker','Prediction Low','Prediction High','Predicted 40ft Dry']);
      
    //     // this.alist[0].listk.reverse();
    //     this.key=Object.keys(this.list[0]);
    //     this.key.unshift("t1", "t2")
    //     console.log(this.key)

    //     var m=0;
        
    //     this.list.forEach(element => {

    //       this.list[m].Month=new Date(element['Month']).getMonth()+'/'+new Date(element['Month']).getFullYear();

    //       m=m+1;
    //     });
        
    //     this.list.forEach(element => {
            
    //       var act=null;
    //       var bunker=null;
          

    //       if(element[this.key[7]]!=0||element[this.key[7]]!='')
    //         act=element[this.key[7]];
    //       if(element[this.key[8]]!=0||element[this.key[8]]!='')
    //       bunker=element[this.key[8]];
        
      
          
    //       dataTable.push([element['Month'],act,bunker,element[this.key[11]],element[this.key[9]],element[this.key[10]]]);
          
       
    //       var   LineChartdata = {
    //         chartType: 'LineChart',
    //         dataTable: [],
    //          options: {
    //           options: {
        
    //             title: 'Monthly trend',
                
    //           },
    //           series: {
    //             0: { color: '#fc0d21' },
    //             1: { color: '#000000',lineDashStyle: [4, 4] },
    //             2: { color: '#17cae6' },
    //             3: { color: '#17cae6' },
    //             4: { color: '#fc0d21',lineDashStyle: [4, 4] },
    //           }
    //             ,
    //         height:430  }
        
    //       }
        
    //       LineChartdata.dataTable=dataTable;
    //       this.LineChartdata.push(JSON.parse(JSON.stringify(LineChartdata)))
        
    //       // this.LineChartdatasmall2.dataTable.push([element['month_year'],element[this.key[21]],element[this.key[22]]]);
    //       // this.LineChartdatasmall3.dataTable.push([element['month_year'],element[this.key[9]]]);
    //       // this.LineChartdatasmal4.dataTable.push([element['month_year'],element[this.key[17]],element[this.key[15]]]);

          
    //     });
    //     this.gflag=true;

    //   }
    // });
  
  }
  changemonth(m){
    this.month=m;
    this.onChangeFunction();
     
  }
  onChangeFunctionLane(){
    
    this.service.postData(this.searchFactore, 'get_ociean_prediction_lane').then((result) => {

      var temp2: any = result;
      this.lanelist=temp2.lane;
      console.log(this.lanelist);
      this.searchFactore.lane=this.lanelist[0];
      this.onChangeFunction();
    });
  }
  getcount(){

   var count=0;

    var rlist = JSON.parse(JSON.stringify(this.list));
    console.log(rlist)
    rlist.reverse();
    console.log(rlist[count][this.key[7]])
    while(rlist[count][this.key[7]]==null)
    {
       count=count+1;
    }

    return count+this.month;
  }
  onChangeFunction(){

    
    this.list=[];
    this.gflag=false;
    this.service.postData(this.searchFactore, 'get_ociean_prediction_by_lane').then((result) => {

      var temp2: any = result;
      // this.lanelist=JSON.parse(temp2.lane);
      this.list=JSON.parse(temp2.data);

      console.log(this.list);
      // this.gflag=true;
      if(this.list.length>0)
      {
        // this.list.reverse();
        this.sublist= this.list.slice(13, 24);   


      
      
        // this.alist[0].listk.reverse();
        this.key=Object.keys(this.list[0]);
        this.key.unshift("t1", "t2")
        console.log(this.key)

        var m=0;
        this.list.forEach(element => {

          // 
           this.list[m].Month=(new Date(element['Month']+86400000).getMonth()+1)+'/'+new Date(element['Month']).getFullYear();

          m=m+1;
        });
        var dataTable=[]
        dataTable.push(['Month','Actual rate','Prediction Low','Prediction High','Predicted Rate']);

        var   LineChartdata = {
          chartType: 'LineChart',
          dataTable: [],
          options: {
            options: {
      
              title: 'Monthly trend',
              
            },
            series: {
              0: { color: '#000000' },
              1: { color: '#17cae6' },
              2: { color: '#17cae6' },
              3: { color: '#fc0d21',lineDashStyle: [4, 4] },
            }
              ,
          height:430  }
      
        }

        var listn=JSON.parse(JSON.stringify(this.list));
        console.log(this.getcount())
        var list=listn.slice(this.getcount()*(-1));
        
        console.log(list)
        list.forEach(element => {
            
          var act=null;
          var bunker=null;
          

          if(element[this.key[7]]!=0||element[this.key[7]]!='')
            act=element[this.key[7]];
          if(element[this.key[8]]!=0||element[this.key[8]]!='')
          bunker=element[this.key[8]];
        
      
      
          
          dataTable.push([element['Month'],act,element[this.key[11]],element[this.key[9]],element[this.key[10]]]);
          
        
        

          // this.LineChartdatasmall2.dataTable.push([element['month_year'],element[this.key[21]],element[this.key[22]]]);
          // this.LineChartdatasmall3.dataTable.push([element['month_year'],element[this.key[9]]]);
          // this.LineChartdatasmal4.dataTable.push([element['month_year'],element[this.key[17]],element[this.key[15]]]);

          
        });
        LineChartdata.dataTable=dataTable;

        
        this.LineChartdata=JSON.parse(JSON.stringify(LineChartdata))

        console.log(this.LineChartdata);
        this.gflag=true;

      }
    });
  }
 


  gotoproj(){
    this.router.navigateByUrl('/projectionanalyser');

  }

  GOtoNExtPage_2() {
    
    this.router.navigateByUrl('/operational-concern');
  }
  GOtoNExtPage_1() {
    
    
    this.router.navigateByUrl('/dashboard');
  }
  GOtoNExtPage_P()
  {
    this.router.navigateByUrl('/operational-concern1');
  }
  GOtomessage() {
      
    this.router.navigateByUrl('/messages');
  }
  
  go_config()
  {
    this.router.navigateByUrl('/mle-training');
  
  }
  goto_index(){
    this.router.navigateByUrl('/drwerydashboard');

  }
  ngOnInit() {}

}
