import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebapiService } from '../webapi.service';

@Component({
  selector: 'app-drwery-weekly-dashboard',
  templateUrl: './drwery-weekly-dashboard.component.html',
  styleUrls: ['./drwery-weekly-dashboard.component.scss'],
})
export class DrweryWeeklyDashboardComponent implements OnInit {
  lanelist
  key=[]
  month=12;
  keyl=[];
  keyg=[]
  gflag=false;
  LineChartdata:any; 

  opt={minimumFractionDigits:0, maximumFractionDigits: 0}
lc:any;
selectedkey=''
  constructor(public router:Router,public service: WebapiService) { 
    this.service.postData({}, 'get_drewry_weekly_data').then((result) => {

      var temp2: any = result;
      this.lanelist=temp2;
    
      this.key=Object.keys(this.lanelist[0]);
      console.log(this.key)
      this.keyl=JSON.parse(JSON.stringify(this.key))
      this.keyl.splice(0, 1);
      this.keyg=JSON.parse(JSON.stringify(this.keyl))
      this.keyg.splice(0, 1);

      console.log(this.keyl)

      var dataTable=[]
      dataTable.push(['Week','Freight rate']);

      this.selectedkey=this.keyl[1]
      var   LineChartdata = {
        chartType: 'LineChart',
        dataTable: [],
        options: {
          options: {
    
            title: 'Weekly trend',
            
          },
          pointSize:3,
          series: {
            0: { color: '#fc0d21' },
            1: { color: '#17cae6' },
            2: { color: '#17cae6' },
            3: { color: '#fc0d21',lineDashStyle: [4, 4] },
          }
            ,
        height:430  }
    
      }

      var listn=JSON.parse(JSON.stringify(this.lanelist));
      var list=listn.slice(this.month*(-1));
      
      console.log(list)

      list.forEach(element => {
          
        // var act=null;
        // var bunker=null;
        

        // if(element[this.key[7]]!=0||element[this.key[7]]!='')
        //   act=element[this.key[7]];
        // if(element[this.key[8]]!=0||element[this.key[8]]!='')
        // bunker=element[this.key[8]];
      
    
    
        dataTable.push([element['Week'],element[this.selectedkey]]);
        
      
      

        // this.LineChartdatasmall2.dataTable.push([element['month_year'],element[this.key[21]],element[this.key[22]]]);
        // this.LineChartdatasmall3.dataTable.push([element['month_year'],element[this.key[9]]]);
        // this.LineChartdatasmal4.dataTable.push([element['month_year'],element[this.key[17]],element[this.key[15]]]);

        
      });
      LineChartdata.dataTable=dataTable;

      
      this.LineChartdata=JSON.parse(JSON.stringify(LineChartdata))

      console.log(this.LineChartdata)
      this.gflag=true
    });
  }
  onChangeFunction(){
    this.changemonth(this.month)
  }
  changemonth(m){
    this.gflag=false;
    var dataTable=[]
    dataTable.push(['Week','Freight rate']);
    this.month=m;
    var   LineChartdata = {
      chartType: 'LineChart',
      dataTable: [],
      options: {
        options: {
  
          title: 'Weekly trend',
          
        },
        pointSize:3,
        series: {
          0: { color: '#fc0d21' },
          1: { color: '#17cae6' },
          2: { color: '#17cae6' },
          3: { color: '#fc0d21',lineDashStyle: [4, 4] },
        }
          ,
      height:430  }
  
    }

    var listn=JSON.parse(JSON.stringify(this.lanelist));
    var list=listn.slice(this.month*(-1));
    
    console.log(list)

    list.forEach(element => {
        
      // var act=null;
      // var bunker=null;
      

      // if(element[this.key[7]]!=0||element[this.key[7]]!='')
      //   act=element[this.key[7]];
      // if(element[this.key[8]]!=0||element[this.key[8]]!='')
      // bunker=element[this.key[8]];
    
  
  
      dataTable.push([element['Week'],element[this.selectedkey]]);
      
    
    

      // this.LineChartdatasmall2.dataTable.push([element['month_year'],element[this.key[21]],element[this.key[22]]]);
      // this.LineChartdatasmall3.dataTable.push([element['month_year'],element[this.key[9]]]);
      // this.LineChartdatasmal4.dataTable.push([element['month_year'],element[this.key[17]],element[this.key[15]]]);

      
    });
    LineChartdata.dataTable=dataTable;

    
    this.LineChartdata=JSON.parse(JSON.stringify(LineChartdata))

    console.log(this.LineChartdata)
    this.gflag=true

  }
  gotoproj(){
    this.router.navigateByUrl('/projectionanalyser');

  }

  GOtoNExtPage_2() {
    
    this.router.navigateByUrl('/operational-concern');
  }
  GOtoNExtPage_1() {
    
    
    this.router.navigateByUrl('/dashboard');
  }
  GOtoNExtPage_P()
  {
    this.router.navigateByUrl('/operational-concern1');
  }
  GOtomessage() {
      
    this.router.navigateByUrl('/messages');
  }
  
  go_config()
  {
    this.router.navigateByUrl('/mle-training');
  
  }
  goto_index(){
    this.router.navigateByUrl('/drwerydashboard');

  }
  ngOnInit() {}

}
